<!-- 我的足迹--评测记录 新 -->
<template>
  <div class="review">
    <!-- <p class="myclass-title">
      <span class="title-text">评测记录</span>
      <span class="title-border"></span>
    </p> -->
    <headers ref="headers" :headname="headName"></headers>

    <!-- 有数据展示 -->
    <div v-show="count>0" class="review_list">
      <div v-for="(item,index) in list" :key="index" class="zhul_sy_zyList">
        <div class="info_top">
          <img src="@/assets/image/shijuan.png" alt="">
          <span class="top_title">{{ item.exam_name }}</span>
          <span class="btn_see" @click="lookReport(item)">查看成绩单</span>
          <span class="btn_ce_again" @click="nextEva(item)">再次测评</span>
          <h1 class="score">{{ item.max_score }}</h1>
          <span class="score_title">最高测评分数：</span>
        </div>

        <div class="info">
          <img :src="item.lesson_thumb" alt="" @click="lessonDetail(item)">
          <div class="center_top">
            <span class="recommend">推荐</span>
            <span class="center_top_title">{{ item.lesson_name }}</span>
          </div>
          <div class="center_bottom">
            <span>好评率： </span><span class="span_red">{{ item.comment_rate }}</span>
            <span style="margin-left:30px;">学员人数： </span><span class="span_red">{{ item.student_num }}</span><span> 人</span>
          </div>
        </div>
      </div>

      <!-- 分页 -->
      <div class="feny">
        <el-pagination
          v-show="count>10"
          class="page"
          background
          layout="prev, pager, next"
          :page-size="params.limit"
          :total="count"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>

    <!-- 无数据显示 -->
    <noData v-show="count===0" ref="noData" :msg="msg"></noData>
    <!-- <div v-show="count===0" class="noDataShow">
			<img src="@/assets/image/kong.png" alt="">
			<p>您还没有评测记录~</p>
		</div> -->

  </div>
</template>

<script>
import { evaluate } from '@/api/lesson.js'
import headers from '@/components/topTitle/index.vue'
import noData from '@/components/noDataBox/index.vue'
export default {
  name: '',
  components: { headers, noData },

  data() {
    return {
      headName: '评测记录',
      msg: '您还没有评测记录~',
      count: 0,
      url_eva: '', // 再次评测链接
      url_eva_result: '', // 查看成绩单链接
      url_lesson: '', // 课程详情链接
      list: [],
      params: {
        page: 1,
        limit: 10
      }
    }
  },

  computed: {},

  created() {
    this.getlist()
  },

  mounted() {},

  methods: {
    getlist() {
      evaluate(this.params).then(res => {
        this.list = res.result.list
        this.count = res.result.count
        console.log(res)
      }).catch(err => {
        console.log(err)
      })
    },
    lookReport(item) {
      // 查看成绩单
      window.open(item.url_eva_result, '_blank')
    },
    nextEva(item) {
      // 再次测评
      window.open(item.url_eva, '_blank')
    },
    lessonDetail(item) {
      // 课程详情
      window.open(item.url_lesson, '_blank')
    },

    // 分页
    handleCurrentChange(val) {
      // 当前点击页数
      this.params.page = val
      this.getlist()
    }
  }
}
</script>

<style lang="scss" scoped>
.review{
  width: 965px;
  padding: 0 0 20px 0;
	min-height: 900px;
  position: relative;
  font-family: 'PingFangSC-Regular';
}
.myclass-title {
	padding: 26px 60px 12px 10px;
	width: 100%;
	font-size: 19px;
	position: relative;
	text-align: left;
}
.title-border{
	position: absolute;
	left: 0;
	bottom: 16px;
	width: 4px;
	height: 20px;
	z-index: 2;
	background: #f04e4f;
}
.review_list{
  margin-left: 30px;
  padding: 0 30px 0 0;
	// pad: 0 13px;
  // background-color: #fff;
}
.zhul_sy_zyList{
	float: left;
	width: 100%;
	border-bottom: 1px solid #DDDDDD;
	padding: 16px 0;
}
.info_top{
  width: 100%;
  height: 80px;
  border-bottom: 1px dashed #DDDDDD;
}
.info_top img{
  float: left;
  width: 26px;
  height: 26px;
  margin-top: 25px;
}
.info_top .top_title{
  float: left;
  margin-top: 25px;
  line-height: 26px;
  margin-left: 5px;
  font-size: 12px;
}
.info_top .btn_see{
  float: right;
  width: 110px;
  line-height: 26px;
  margin-top: 25px;
  font-size: 14px;
  background-color: #fff;
  color: #F04E4F;
  border: 1px solid #F04E4F;
  border-radius: 10px;
  cursor: pointer;
}
.info_top .btn_ce_again{
  float: right;
  width: 110px;
  line-height: 26px;
  margin-top: 25px;
  margin-right: 20px;
  font-size: 14px;
  background-color: #F04E4F;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
}
.info_top .score{
  float: right;
  line-height: 26px;
  margin-top: 25px;
  margin-right: 50px;
  font-size: 18px;
  font-weight: bold;
  color: #F04E4F;
}
.info_top .score_title{
  float: right;
  line-height: 26px;
  margin-top: 25px;
  margin-right: 5px;
  font-size: 12px;
}
.info{
  width: 100%;
  height: 81px;
}
.info img{
  float: left;
  width: 86px;
  height: 66px;
  margin-top: 15px;
  cursor: pointer;
}
.center_top{
  float: left;
  margin-top: 25px;
  margin-left: 10px;
  width: 789px;
  height: 20px;
  .recommend{
    float: left;
    width: 40px;
    line-height: 20px;
    font-size: 12px;
    background-color: #F04E4F;
    color: #fff;
    border-radius: 10px;
  }
  .center_top_title{
    float: left;
    margin-left: 6px;
    line-height: 20px;
    font-size: 12px;
  }
}
.center_bottom{
  float: left;
  margin-top: 10px;
  margin-left: 10px;
  width: 789px;
  height: 20px;
  font-size: 12px;
  color: #333333;
  text-align: left;
  .span_red{
    color: #F04E4F;
  }
}
.noDataShow{
	position: absolute;
	width: 180px;
	height: 180px;
	top: 50%;
	left: 50%;
	margin-top: -90px;
}
.noDataShow img {
	display: block;
	margin: auto;
}
.noDataShow p {
	text-align: center;
	font-size: 14px;
	font-family: "微软雅黑","Aria",Arial, Helvetica, sans-serif;
	color: #999999;
	margin-top: 30px;
}
.feny{
	display: inline-block;
  margin-top: 20px;
}

</style>
